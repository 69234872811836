<template>
  <div>
    <!-- <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="show = true"
    >
      Show Modal
    </b-button> -->

    <b-modal v-model="show" size="xl" title="รายละเอียด">
      <div>
        <b-card-body>
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <br />
          จำนวนทั้งหมด {{ totalRows }} ตัว
        </b-card-body>
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
          style="height: 525px"
        >
          <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(status)="data">
              <b-badge
                v-if="data.item.status == 'Online' || data.item.status == 'online'"
                variant="success"
              >
                online
              </b-badge>
              <b-badge
                v-else-if="data.item.status == 'Offline' || data.item.status == 'offline'"
                variant="danger"
              >
                offline
              </b-badge>
              <b-badge v-else variant="info">
                {{ data.item.status }}
              </b-badge>
            </template>

            <template #cell(title)="data">
              <p>
                {{ data.item.mac }} ที่ {{ data.item.title }}
                <span v-if="data.item.description">({{ data.item.description }} )</span>
                {{ data.item.district == "" ? "ตำบล" + data.item.district : "" }}
                {{ data.item.amphoe == "" ? "อำเภอ" + data.item.amphoe : "" }}
                {{ data.item.province == "" ? "จังหวัด" + data.item.province : "" }}
              </p>
            </template>
          </b-table>
        </vue-perfect-scrollbar>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="float-right"
            @click="show = false"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCardBody,
  BProgress,
  BFormGroup,
  BImg,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTable,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BInputGroupAppend,
  BButton,
  BPagination,
  BModal,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2";
import "animate.css";
import api from "@/api";
import { CloudSnowIcon } from "vue-feather-icons";

export default {
  components: {
    BModal,
    VBModal,
    VuePerfectScrollbar,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCardBody,
    BProgress,
    BBadge,
    BFormGroup,
    BImg,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BTable,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BInputGroupAppend,
    BButton,
    BPagination,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    items: [],
    show: false,
    data: [],
    groub: [],
    perfectScrollbarSettings: {
      maxScrollbarLength: 40,
      wheelPropagation: false,
    },
    filterOn: [],
    fields: [
      { key: "title", label: "venue" },
      { key: "mac", label: "mac" },
      { key: "lastseen", label: "ดึงข้อมูลเมื่อ", sortable: true },
      { key: "status", label: "status", sortable: true },
    ],
    /* eslint-disable global-require */
    items: [],
    perPage: 100,
    pageOptions: [100, 200, 500],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
  }),
  mounted() {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async showmodel(data, title) {
      // console.log("data", data);
      // console.log("title :>> ", title);
      this.items = data;
      this.totalRows = data.length;

      this.show = true;
      this.filter = "";
      setTimeout(() => {
        this.filter = title;
      },200);
    },
  },
};
</script>
