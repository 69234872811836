<template>
  <div style="margin-left: 5px; margin-right: 5px">
    <b-card align="center">
      <h2>Dashboard</h2>
    </b-card>
    <b-row style="margin-top: -1.6em">
      <b-col lg="6" md="12">
        <b-card>
          <b-card-title>Gateway</b-card-title>

          <b-card-body>
            <b-row>
              <b-col lg="4" md="4">
                <b-media no-body  @click="show_data(Gateway_data_Online, '')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="success">
                      <feather-icon size="24" icon="WifiIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto" >
                    <h4 class="font-weight-bolder mb-0">ออนไลน์</h4>
                    <b-card-text class="font-small-5 mb-0">
                      <b>{{ GwOnline }}</b>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col lg="4" md="4">
                <b-media no-body  @click="show_data(Gateway_data, '')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="danger">
                      <feather-icon size="24" icon="WifiOffIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">ออฟไลน์</h4>
                    <b-card-text class="font-small-5 mb-0">
                      <b>{{ GwOffline }}</b>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col lg="4" md="4">
                <b-media no-body  @click="show_data(Gateway_data_Install, '')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="info">
                      <feather-icon size="24" icon="RadioIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">ติดตั้งแล้ว</h4>
                    <b-card-text class="font-small-5 mb-0">
                      <b>{{ GwStatusInstall }}</b>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="6" md="12">
        <b-card>
          <b-card-title>AP</b-card-title>

          <b-card-body>
            <b-row>
              <b-col lg="4" md="4">
                <b-media no-body  @click="show_data(ApDataOnline, '')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="success">
                      <feather-icon size="24" icon="WifiIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">ออนไลน์</h4>
                    <b-card-text class="font-small-5 mb-0">
                      <b>{{ ApOnline }}</b>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col lg="4" md="4">
                <b-media no-body  @click="show_data(AP_data, '')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="danger">
                      <feather-icon size="24" icon="WifiOffIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">ออฟไลน์</h4>
                    <b-card-text class="font-small-5 mb-0">
                      <b>{{ ApOffline }}</b>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col lg="4" md="4">
                <b-media no-body  @click="show_data(ApDataOther, '')">
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="warning">
                      <feather-icon size="24" icon="AlertTriangleIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">อืนๆ</h4>
                    <b-card-text class="font-small-5 mb-0">
                      <b>{{ ApOther }}</b>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row style="margin-top: -1.6em">
      <b-col lg="3" md="6">
        <b-card>
          <b-card-title>VPN</b-card-title>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
            style="height: 525px"
          >
            <!-- <div v-for="(Item, index) in device_register" :key="index">
              {{ Item.title }} เวลา: {{ Item.datetime }} <br />เบอร์โทร:
              {{ Item.phone }} เพศ: {{ Item.gender }} <br />
              ประเทศ: {{ Item.country }}

              <hr />
            </div> -->
          </vue-perfect-scrollbar>
        </b-card>
      </b-col>
      <b-col lg="3" md="6">
        <b-card>
          <b-card-title
            >DOWN Gateway &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b-button
              variant="primary"
              class="ml-auto"
              @click="show_data(Gateway_data, '')"
            >
              <span class="font-weight-bold d-none d-md-block">ดูทั้งหมด</span>
            </b-button>
          </b-card-title>

          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
            style="height: 525px"
          >
            <div
              v-for="(Item, index) in Gateway_data"
              :key="index"
              @click="show_data(Gateway_data, Item.title)"
            >
              {{ index + 1 }}. <code>{{ Item.title }} </code><br />
              mac: <code>{{ Item.mac }}</code> <br />
              สถานะ: <code> {{ Item.status }}</code> <br />ดึงข้อมูลล่าสุด:
              <code> {{ Item.lastseen }}</code> <br />

              <hr />
            </div>
          </vue-perfect-scrollbar>
        </b-card>
      </b-col>
      <b-col lg="3" md="6">
        <b-card>
          <b-card-title
            >DOWN AP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b-button variant="primary" class="ml-auto" @click="show_data(AP_data, '')">
              <span class="font-weight-bold d-none d-md-block">ดูทั้งหมด</span>
            </b-button>
          </b-card-title>

          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
            style="height: 525px"
          >
            <div
              v-for="(Item, index) in AP_data"
              :key="index"
              @click="show_data(AP_data, Item.title)"
            >
              {{ index + 1 }}. <code>{{ Item.title }}</code
              ><br />
              mac: <code>{{ Item.mac }}</code> <br />
              สถานะ:<code> {{ Item.status }}</code
              ><br />ดึงข้อมูลล่าสุด: <code> {{ Item.lastseen }}</code> <br />

              <hr />
            </div>
          </vue-perfect-scrollbar>
        </b-card>
      </b-col>
      <b-col lg="3" md="6">
        <b-card>
          <b-card-title>เชื่อมต่อล่าสุด</b-card-title>

          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
            style="height: 525px"
          >
            <div v-for="(Item, index) in connection" :key="index" :id="Item.phone">
              venue {{ Item.title }} เวลา : {{ Item.datetime }} เบอร์โทร:
              {{ Item.phone }}

              <hr />
            </div>
          </vue-perfect-scrollbar>
        </b-card>
      </b-col>
    </b-row>

    <model_data ref="modeldata"></model_data>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BCardHeader,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BCardText,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BListGroupItem,
  BButton,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeColors } from "@themeConfig";
import api from "@/api";
import model_data from "./model.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
const chartColors = {
  primaryColorShade: "#836AF9",
  yellowColor: "#ffe800",
  successColorShade: "#28dac6",
  warningColorShade: "#ffe802",
  warningLightColor: "#FDAC34",
  infoColorShade: "#299AFF",
  greyColor: "#4F5D70",
  blueColor: "#2c9aff",
  blueLightColor: "#84D0FF",
  greyLightColor: "#EDF1F4",
  tooltipShadow: "rgba(0, 0, 0, 0.25)",
  lineChartPrimary: "#666ee8",
  lineChartDanger: "#ff4961",
  labelColor: "#6e6b7b",
  grid_line_color: "rgba(200, 200, 200, 0.2)",
};

// check_gateway_online_office
export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BListGroupItem,
    BButton,
    VuePerfectScrollbar,
    model_data,
  },
  data() {
    return {
      Loading: false,
      polarChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            position: "right",
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 10,
              fontColor: chartColors.labelColor,
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scale: {
            scaleShowLine: true,
            scaleLineWidth: 1,
            ticks: {
              display: false,
              fontColor: chartColors.labelColor,
            },
            reverse: false,
            gridLines: {
              display: false,
            },
          },
          animation: {
            animateRotate: false,
          },
        },
        data: {
          labels: [
            "ติดตั้งแล้ว",
            "รอติดตั้ง",
            "Online",
            "Offline",
            // "America",
            // "Antarctica",
            // "Australia",
          ],
          datasets: [
            {
              label: "Population (millions)",
              backgroundColor: [
                $themeColors.primary,
                chartColors.warningColorShade,
                chartColors.successColorShade,
                chartColors.lineChartDanger,
                // chartColors.greyColor,
                // chartColors.successColorShade,
              ],
              data: [10, 19, 17.5, 15],
              borderWidth: 0,
            },
          ],
        },
      },
      total_key: "",
      total_data: "",
      total_key_ap: "",
      total_data_ap: "",
      on_off_key: "",
      on_off_data: "",
      install_key: "",
      install_data: "",
      data: "",
      connect_alert: "",
      hotspotGateway: "",
      hotspotAP: "",
      connection: "",
      device_register: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 20,
        wheelPropagation: false,
      },
      GwOffline: 0,
      GwOnline: 0,
      GwStatusInstall: 0,
      GwTotal: 0,
      Gateway_data: [],
      Gateway_data_Online: [],
      Gateway_data_UnInstall: [],
      Gateway_data_Install: [],

      ApOnline: 0,
      ApOffline: 0,
      ApOther: 0,
      ApTotal: 0,
      AP_data: [],
      ApDataOnline: [],
      ApDataOther: [],
    };
  },

  mounted() {
    this.load();
    // this.loaddata();
    // this.loaddataAP();
    this.socket_connection();
    this.socket_device_register();
    // Pusher.logToConsole = true;
    // var pusher = new Pusher("c17b5afff3d12d8c3bbc", {
    //   cluster: "ap1",
    //   wsHost: "api2.buzzprivilegewifi.com",
    //   wsPort: 6001,
    //   enabledTransports: ["ws", "wss"],
    //   forceTLS: false,
    //   authEndpoint: "api2.buzzprivilegewifi.com/broadcasting/auth",
    //   auth: {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("accessToken"),
    //     },
    //   },
    // });

    // const channel = pusher.subscribe("my-channel");
    // channel.bind("my-event", (data) => {
    //   console.log(data.message);
    //   // var data =  data.message.split("_")
    //   // console.log('data.message.split("_") :>> ', data.message.split("_"));
    //   this.connect_alert = data.message.split("_")[1];
    //   this.$toast({
    //     component: ToastificationContent,
    //     position: "top-right",
    //     props: {
    //       title: data.message.split("_")[4],
    //       icon: "CoffeeIcon",
    //       variant: "success",
    //       text: data.message.split("_")[5],
    //     },
    //   });
    //   this.socket_connection();
    // });
  },
  methods: {
    load() {
      this.Loading = false;
      api
        .post("/reports_noc")
        .then((response) => {
          console.log("response.data :>> ", response.data);

          this.GwOffline = response.data.GwOffline;
          this.GwOnline = response.data.GwOnline;
          this.GwStatusInstall = response.data.GwStatusInstall;
          this.GwTotal = response.data.GwTotal;
          this.Gateway_data = response.data.Gateway_data;
          this.Gateway_data_Online = response.data.Gateway_data_Online;
          this.Gateway_data_UnInstall = response.data.Gateway_data_UnInstall;
          this.Gateway_data_Install = response.data.Gateway_data_Install;

          this.ApOnline = response.data.ApOnline;
          this.ApOffline = response.data.ApOffline;
          this.ApOther = response.data.ApOther;
          this.ApTotal = response.data.ApTotal;
          this.AP_data = response.data.AP_data;
          this.ApDataOnline = response.data.ApDataOnline;
          this.ApDataOther = response.data.ApDataOther;

          this.Loading = true;
        })
        .catch((error) => {});
    },
    show_data(data, title) {
      // console.log('data :>> ', data);
      // console.log('title :>> ', title);
      this.$refs.modeldata.showmodel(data, title);
    },
    loaddata() {
      this.Loading = false;
      api
        .get("/check_gateway_online_office")
        .then((response) => {
          // console.log("check_gateway_online_office.data :>> ", response.data);
          this.total_key = response.data.total_key;
          this.total_data = response.data.total_data;
          this.on_off_key = response.data.on_off_key;
          this.on_off_data = response.data.on_off_data;
          this.install_key = response.data.install_key;
          this.install_data = response.data.install_data;
          this.hotspotGateway = response.data.hotspot;
          // console.log("total_key :>> ", this.total_key);
          // console.log("total_data :>> ", this.total_data);
          // console.log("on_off_key :>> ", this.on_off_key);
          // console.log("on_off_data :>> ", this.on_off_data);
          // console.log("install_key :>> ", this.install_key);
          // console.log("install_data :>> ",this.install_data);
          this.polarChart = {
            ...this.polarChart,
            ...{
              data: {
                labels: total_key,
                datasets: [
                  {
                    data: total_data,
                    label: "Population (millions)",
                    backgroundColor: [
                      $themeColors.primary,
                      chartColors.warningColorShade,
                      chartColors.successColorShade,
                      chartColors.lineChartDanger,
                    ],
                  },
                ],
              },
            },
          };
          this.Loading = true;
        })
        .catch((error) => {});
    },
    loaddataAP() {
      this.Loading = false;
      api
        .get("/check_ap_online_office")
        .then((response) => {
          console.log("response.api_online_office :>> ", response.data);
          this.total_key_ap = response.data.total_key;
          this.total_data_ap = response.data.total_data;
          // console.log('this.total_key_ap :>> ', this.total_data_ap);
          // console.log('this.total_data_ap :>> ', this.total_data_ap);

          this.Loading = true;
        })
        .catch((error) => {});
    },
    socket_connection() {
      this.Loading = false;
      api
        .get("/socket_connection")
        .then((response) => {
          // console.log("response.socket_connection :>> ", response.data);
          this.connection = response.data;
          console.log("this.connect_alert :>> ", this.connect_alert);
          setTimeout(() => {
            // console.log("docume :>> ",document.getElementById(this.connect_alert)
            // );
            document.getElementById(this.connect_alert).classList.add("neon");
            // console.log("Delayed for 5 second.");
          }, "5000");
          setTimeout(() => {
            // console.log("docume :>> ",document.getElementById(this.connect_alert)
            // );
            document.getElementById(this.connect_alert).classList.remove("neon");
            // console.log("Delayed for 15 second.");
          }, "15000");

          this.Loading = true;
        })
        .catch((error) => {});
    },
    socket_device_register() {
      this.Loading = false;
      api
        .get("/socket_device_register")
        .then((response) => {
          // console.log("response.socket_device_register :>> ", response.data);
          this.device_register = response.data;

          this.Loading = true;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang="scss">
.neon {
  color: rgb(19, 157, 33);
  animation: glow 1s linear infinite;
  -moz-animation: glow 1s linear infinite;
  -webkit-animation: glow 1s linear infinite;
  -o-animation: glow 1s linear infinite;
}

@keyframes glow {
  50% {
    opacity: 0;
  }
}
</style>
